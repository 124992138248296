import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`今の段階では、ボリュームメーターなどでリファレンスと比べても`}<strong parentName="p">{`数値は合わない`}</strong>{`ので、気にしなくて大丈夫です。`}</p>
      <p><strong parentName="p">{`補足２：`}</strong></p>
      <p>{`自分のMIXにある楽器が、リファレンスにはない場合があります。しかし、同じ楽器構成のリファレンスを正しく選んでいれば、「主要な楽器」はあるはずです。言い換えると、リファレンスにない楽器とは「主要ではない楽器」であり、「`}<strong parentName="p">{`チョイ役の楽器`}</strong>{`」です。`}</p>
      <p>{`これらの楽器は、ボーカルを邪魔しない範囲の大きさにとどめておきます。なぜなら、動画にあるように`}<strong parentName="p">{`「一番大切な楽器を、他の楽器は邪魔をしないようにする」のが原則`}</strong>{`だからです。`}</p>
      <p>{`もちろん、「リファレンスに大きさを合わせる」という作業できませんが、別の言い方をすると「そこまで厳密にやるほどの重要性がない楽器」と言えます。`}</p>
      <p><strong parentName="p">{`補足３：`}</strong></p>
      <p>{`動画の通り、リファレンスと同じ大きさになるように`}<br parentName="p"></br>{`
`}{`各楽器の音量を上げていくと、`}<br parentName="p"></br>{`
`}{`曲全体の音量が0dBを超えることがあります。`}</p>
      <p>{`しかし、３つ目の手順の「強弱」の最後に`}<br parentName="p"></br>{`
`}{`リミッターという道具を使えば`}<br parentName="p"></br>{`
`}{`0dBを超えることはなくなりますので、`}<br parentName="p"></br>{`
`}<strong parentName="p">{`それまでは気にせず進めて大丈夫`}</strong>{`です。`}</p>
      <p>{`教本によっては、「MIX中に一度でも0dBを超えてはいけない」と書いてあることがありますが、これは情報が古すぎます。現在は、ほとんどのDAWが`}<strong parentName="p">{`MIX中に0dBを超えても何の問題もない設計`}</strong>{`になっています（32bit-float内部処理によるものです。※waveファイルの32bit-floatとは別物です）。`}</p>
      <p>{`唯一、問題となるのは、MIXが完了した後にファイルに書き出す時で、この時に0dBを超えていると音が割れます。これさえ避けられればよいので、MIX中は0dBをさほど気にする必要はありません。`}<br parentName="p"></br>{`
`}{`ただ、もちろん常に0dBを遥かに超えているようでは音が割れますので、そうならないように、今は`}<strong parentName="p">{`リファレンスを-6dBした音量に合わせている`}</strong>{`のです。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      